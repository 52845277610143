<template>
	<div class="route-con">
		<div class="route" @click="selectRoute" :class="{ 'route-hidden': r.hidden }">

			<div class="route-preview" v-if="preview">
				<img :src="preview" alt="preview" />
			</div>

			<div class="route-date">
				<span class="utc">UTC</span>
				<span v-if="r.startTime">{{r.startTime | date('YYYY.MM.DD')}}</span>
				<span v-else>{{r.createDate | date('YYYY.MM.DD')}}</span>
				<div class="route-time">
					<div>
						<img src="@/assets/takeoff.png" alt="takeoff" />
						<span v-if="r.startTime">{{r.startTime | date('HH:mm')}}</span>
						<span v-else>{{r.createDate | date('HH:mm')}}</span>
					</div>
					<div>
						<img src="@/assets/landing.png" alt="landing" />
						<span v-if="r.endTime">{{r.endTime | date('HH:mm')}}</span>
						<span v-else>?</span>
					</div>
				</div>
			</div>

			<div class="route-plane">
				<img :src="image" alt="plane" />
				<div>
					<span>{{r.registrationNumber}}</span>
				</div>
			</div>

			<div class="route-plan" v-if="r.routePlan">
				<img src="@/assets/route.png" alt="routeplan">
				<span>{{r.routePlan.name || '-'}}</span>
			</div>
			<div class="route-plan" v-else>
			</div>


			<div class="route-user" v-if="r.user">
				<Avatar :user="r.user" class="avatar" />
				<div>
					<span v-if="r.user.name">{{r.user.name}}</span>
					<span>@{{r.user.username}}</span>
				</div>
			</div>

			<div class="route-user" v-else>
				<Avatar avatar="unknown" class="avatar" avatar-color="#D9D9D9" />
				<div>
					<span>{{ $t('routeCard.unknown') }}</span>
				</div>
			</div>

			<div class="route-actions">
				<share-button type="route" :shared-id="String(r.id)" size="md" v-if="!r.hidden" />
				<button class="btn btn-primary" @click="selectRoute" v-if="!r.hidden">
					<font-awesome-icon icon="eye" class="m-0 align-self-end" />
				</button>
				<button class="btn btn-warning" @click.stop="toggleHidden(true)" v-if="!r.hidden">
					<font-awesome-icon icon="eye-slash" class="m-0 align-self-end" />
				</button>
				<button class="btn btn-warning" @click.stop="toggleHidden(false)" v-if="r.hidden">
					<font-awesome-icon icon="undo" class="m-0 align-self-end" />
				</button>
			</div>

		</div>
		<div class="route-mobile" @click="selectRoute" :class="{ 'route-hidden': r.hidden }">
			<div class="route-preview" v-if="preview">
				<img :src="preview" alt="preview" />
			</div>

			<div class="route-date">
				<span class="utc">UTC</span>
				<span v-if="r.startTime">{{r.startTime | date('YYYY.MM.DD')}}</span>
				<span v-else>{{r.createDate | date('YYYY.MM.DD')}}</span>
				<div class="route-time">
					<div>
						<img src="@/assets/takeoff.png" alt="takeoff" />
						<span v-if="r.startTime">{{r.startTime | date('HH:mm')}}</span>
						<span v-else>{{r.createDate | date('HH:mm')}}</span>
					</div>
					<div>
						<img src="@/assets/landing.png" alt="landing" />
						<span v-if="r.endTime">{{r.endTime | date('HH:mm')}}</span>
						<span v-else>?</span>
					</div>
				</div>
			</div>


			<div class="route-body">
				<div class="route-plane">
					<img :src="image" alt="plane" />
					<div>
						<span>{{r.registrationNumber}}</span>
					</div>
				</div>

				<div class="route-plan" v-if="r.routePlan">
					<img src="@/assets/route.png" alt="routeplan">
					<span>{{r.routePlan.name || '-'}}</span>
				</div>

				<div class="route-user" v-if="r.user">
					<Avatar :user="r.user" class="avatar" />
					<div>
						<span v-if="r.user.name">{{r.user.name}}</span>
						<span>@{{r.user.username}}</span>
					</div>
				</div>

				<div class="route-user" v-else>
					<Avatar avatar="unknown" class="avatar" avatar-color="#D9D9D9" />
					<div>
						<span>{{ $t('routeCard.unknown') }}</span>
					</div>
				</div>
			</div>

			<div class="route-actions">
				<share-button type="route" :shared-id="String(r.id)" size="md" v-if="!r.hidden" />
				<button class="btn btn-primary" @click="selectRoute" v-if="!r.hidden">
					<font-awesome-icon icon="eye" class="m-0 align-self-end" />
					{{$t('routeCard.view')}}
				</button>
				<button class="btn btn-warning" @click.stop="toggleHidden(true)" v-if="!r.hidden">
					<font-awesome-icon icon="eye-slash" class="m-0 align-self-end" />
					{{$t('routeCard.hide')}}
				</button>
				<button class="btn btn-warning" @click.stop="toggleHidden(false)" v-if="r.hidden">
					<font-awesome-icon icon="undo" class="m-0 align-self-end" />
					{{$t('routeCard.unhide')}}
				</button>

			</div>

		</div>
	</div>
</template>

<script>
import Avatar from "@/components/profile/avatar/Avatar.vue";
import { getPlaneIcon } from "@/plugins/planeUtils";
import ShareButton from "@/components/sharing/ShareButton.vue";

export default {
	name: 'RouteCard',
	components: { ShareButton, Avatar },
	emits: ['update'],
	props: {
		route: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			/** @type {Route} */
			r: null,

			preview: null,
		}
	},
	methods: {
		async setPreview() {
			if(!this.r.hasPreview) {
				this.preview = null;
				return;
			}

			const imgUrl = `${this.$rest.baseUrl}/route/${this.r.id}/preview`;
			const http = new XMLHttpRequest();

			http.open('HEAD', imgUrl, false);
			http.send();


			if(http.status === 404) {
				this.preview = null;
				return;
			}
			this.preview = imgUrl;
		},
		selectRoute() {
			if(this.r.hidden) return;
			this.$router.push({name: 'flight-observer', params: {"id": this.r.id}});
		},
		async toggleHidden(hidden) {
			if(hidden) {
				if(!(await this.$confirm(this.$t('routeCard.hideConfirm')))) return;
			}
			await this.$rest.toggleHiddenRoute(this.r.id, hidden);
			this.$emit('update');
		}
	},
	computed: {
		image() {
			return getPlaneIcon(this.r.beaconType)
		}
	},
	created() {
		this.r = this.route;
		this.setPreview();
	}
}
</script>

<style scoped lang="css" >

.route-con{
		width: 100%;
		max-width: 1248px;
}

.route {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #f8f9fa;
	border-radius: 1rem;
	width: 100%;
	padding: 1rem 2rem;
	gap: 2rem;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
	cursor: pointer;
	//transition: all 0.1s ease-in-out;
}

.route-hidden {
	background-color: #eff2f4 !important;
	cursor: default !important;
}

.route:hover {
	background-color: #f4f6f7;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.4);
}

.route-date {
	font-size: 1.3rem;
	font-weight: 700;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 180px;
}

.route-date .utc {
	font-size: 0.7rem;
	font-weight: 400;
	color: #6c757d;
}

.route-date .route-time {
	display: flex;
	align-items: center;
	gap: 1rem;
}

.route-date .route-time div {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
	font-size: 1rem;
	font-weight: 500;
}

.route-date .route-time div img {
	width: 1.2rem;
}

.route-plane {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 0.5rem;
}


.route-plane img {
	width: 2.1rem;
	transform: rotate(45deg);
		flex-shrink: 1;
}

.route-plane div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.route-plane div span {
	font-size: 1rem;
	font-weight: 500;
	min-width: 100px;
	overflow: hidden;
	white-space: pre-wrap;
	text-overflow: ellipsis;
	line-height: 1.5em;
	max-height: 3em;
		word-break: break-word;
}


.route-plan {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
	min-width: 150px;
}

.route-plan img {
	width: 2.1rem;
}

.route-plan span {
	font-size: 1rem;
	font-weight: 500;
	max-width: 100px;
	min-width: 100px;
	white-space: pre-wrap;
	overflow: hidden;
	text-overflow: ellipsis;
	line-height: 1.5em;
	max-height: 3em;

}


.route-user {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 1rem;
}

.route-user .avatar {
	width: 2.5rem;
	height: 2.5rem;
}

.route-user div {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.route-user div span {
	font-size: 0.8rem;
	font-weight: 500;
	max-width: 100px;
	min-width: 100px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.route-user div span:first-child {
	font-size: 1rem;
	font-weight: 700;
}

.route-preview {
		flex-shrink: 0;
	width: 100px;
	border-radius: 0.5rem;
	overflow: hidden;
}




.route-preview img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.route-mobile{
	display: none;
}



.route-actions {
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
}

@media screen and (max-width: 1024px) and (min-width: 800px) {
    .route-user div span:first-child {
				font-size: 0.8rem;
		}

    .route-user div span {
				font-size: 0.7rem;
		}

    .route-user .avatar {
				width: 2rem;
				height: 2rem;
		}

    .route-plan img {
				width: 1.8rem;
		}

    .route-plan{
				min-width: 120px;
		}

    .route-plan span {
				font-size: 0.8rem;
				max-width: 85px;
				min-width: 85px;
		}

    .route-plane div span {
				font-size: 0.8rem;
				min-width: 85px;
				max-width: 85px;
		}

    .route-plane img {
				width: 1.8rem;
		}
    .route .route-date .route-time div img {
				width: 1rem;
		}
    .route .route-date .route-time div {
				font-size: 0.8rem;
		}
    .route .route-date .route-time {
				gap: 0.5rem;
		}
    .route .route-date {
				font-size: 1rem;
		}
    .route {
				padding: 1rem;
				gap: 0.5rem;
		}

    .route-preview {
        width: 60px;
    }
}

@media screen and (max-width: 800px) {

		.route-con {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
		}
		.route {
			display: none;
			visibility: hidden;
			opacity: 0;
		}
		.route-mobile{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			background-color: #f8f9fa;
			border-radius: 1rem;
			width: 100%;
			padding-bottom: 1rem;
			box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
			cursor: pointer;
			overflow: hidden;
			max-width: 400px;
			gap: 1rem;
		}

    .route-body {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.2rem;
    }

		.route-preview{
				width: 100%;
				border-radius: 0;
		}

		.route-actions {
				margin-top: 2rem;
		}

		.route-user div span:first-child {
				font-size: 1rem;
		}

		.route-user div span {
				font-size: 0.8rem;
		}

		.route-user .avatar {
				width: 2.3rem;
				height: 2.3rem;
		}

		.route-plan img {
				width: 2rem;
		}

		.route-plan{
				min-width: 140px;
		}

		.route-plan span {
				font-size: 1rem;
				max-width: 100px;
				min-width: 100px;
		}

		.route-plane div span {
				font-size: 1.1rem;
				min-width: 100px;
				max-width: unset;
		}

		.route-plane img {
				width: 2rem;
		}
		.route-date {
				margin-top: 1rem;
		}

}

@media screen and (max-width: 400px) {
		.route-body{
				flex-direction: column;
				align-items: center;
				justify-content: center;
		}
}


</style>

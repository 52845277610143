<template>
  <div class="routes-con">

		<div class="filters-con">
			<div class="filters">
				<div class="filter">
					<label for="startDate">{{ $t('flightList.startDate') }}</label>
					<Calendar id="startDate" v-model="filters.from" panelClass="calendar-class"  :placeholder="startDatePlaceholder" dateFormat="yy.mm.dd"  />
				</div>
				<div class="filter">
					<label for="endDate">{{ $t('flightList.endDate') }}</label>
					<Calendar id="endDate" v-model="filters.to" panelClass="calendar-class" :placeholder="endDatePlaceholder" dateFormat="yy.mm.dd" />
				</div>
				<div class="filter">
					<label for="registrationNumber">{{ $t('flightList.registrationNumber') }}</label>
					<InputText id="registrationNumber" v-model="filters.registrationNumber" placeholder="HAVOA" />
				</div>
				<div class="filter">
					<label for="routePlan">{{ $t('flightList.routePlan') }}</label>
					<InputText id="routePlan" v-model="filters.routePlan" placeholder="Budapest-Pécs" />
				</div>
				<div class="filter">
					<label for="user">{{ $t('flightList.user') }}</label>
					<InputText id="user" v-model="filters.user" placeholder="Teszt Elek" />
				</div>
			</div>
			<div class="d-flex gap-1 align-items-center justify-content-center w-100">
				<label for="hidden" class="mb-0 text-muted">{{ $t('flightList.hidden') }}</label>
				<Checkbox id="hidden" v-model="filters.hidden" :binary="true" />
			</div>
		</div>



		<div class="routes">
			<route-card :route="r" v-for="r in routes"  :key="r.id" @update="filterRoutes" />
			<Skeleton v-if="loading" :count="4" width="100%"  class="skeletons" height="103px" />
			<ServerError v-if="error && !loading" @retry="onScroll(true)" />
			<div id="masonry" v-if="!loading && !error"></div>
		</div>
		<div v-if="routes.length === 0 && !loading">
			<h5>{{ $t('flightList.noFlights') }}</h5>
		</div>
	</div>
</template>

<script>

import { FilterMatchMode } from "primevue/api/";
import RouteCard from "@/components/route/RouteCard.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Calendar from 'primevue/calendar';
import moment from "moment";
import { Skeleton } from "vue-loading-skeleton";
import ServerError from "@/components/ServerError.vue";
import Checkbox from "primevue/checkbox";

export default {
  name: 'FlightListView',
	components: { Checkbox, ServerError, RouteCard, Calendar, Skeleton },
  data() {
    return {
			pageSize: 20,
			filterTimeout: null,
			loading: false,
			/** @type {Route[]} */
      routes: [],
			/** @type {Route[]} */
			filteredRoutes: [],
			/** @type {Route[]} */
			allRoutes: [],
			offset: 0,
      filters: {
        'registrationNumber': null,
        'from': null,
        'to': null,
        'routePlan': null,
        'user': null,
				'hidden': false,
      },
			page: 0,
			noMore: false,
			lastPage: -1,
			ids: [],
			error: false,
		};
  },
  props: {
    app: Object
  },
  watch: {
		filters: {
			handler: function (val) {
				clearTimeout(this.filterTimeout);
				this.filterTimeout = setTimeout(async () => {
					await	this.filterRoutes();
				}, 500);
			},
			deep: true
		},
		offset: {
			handler: function (val) {
				this.routes = [...this.routes, ...this.filteredRoutes.splice(val, this.pageSize)];
			},
			deep: true
		}
  },
	computed: {
		startDatePlaceholder(){
			return moment().format("YYYY.MM.DD");
		},
		endDatePlaceholder(){
			return moment().add(1, 'days').format("YYYY.MM.DD");
		},
	},
  async created() {

  },
	async mounted() {
		const mainArea = document.getElementsByClassName('mainArea')[0];
		const masonry = document.getElementById('masonry');
		if(!mainArea || !masonry) {
			console.error("masonry not found");
			return;
		}

		await this.onScroll(true);

		mainArea.addEventListener('scroll', async () => {
			await this.onScroll();
		});

	},
	methods: {
		isInViewport(el) {
			if(!el) return false;
			const rect = el.getBoundingClientRect();
			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		},
		getFilter(key) {
				const filter = this.filters[key]
				if(!filter) {
					return null;
				}
				if(filter.length < 1) {
					return null;
				}
				return filter;
		},
		async filterRoutes() {
			this.page = 0;
			this.noMore = false;
			this.routes = [];
			this.ids = [];
			this.lastPage = -1;
			await this.getRoutes(this.page);
		},
		async onScroll(forced) {
			if(this.noMore) {
				return;
			}
			if(!this.isInViewport(document.getElementById('masonry')) && !forced) {
				return;
			}
			await this.getRoutes(this.page);
			this.page += 1;

			if(this.isInViewport(document.getElementById('masonry'))) { // if still in viewport, load more
				await this.onScroll();
			}
		},

		/**
		 * @param {number} page
		 * */
		async getRoutes(page) {
			if(this.lastPage >= page) { // prevent double loading
				return;
			}

			this.loading = true;

			this.lastPage = page;

			let routes = await this.$rest.getRoutes(page, this.pageSize, {
				...this.filters,
				from: this.getFilter('from') ? moment(this.getFilter('from')).format("YYYY-MM-DD") : null,
				to: this.getFilter('to') ? moment(this.getFilter('to')).format("YYYY-MM-DD") : null,
			});

			if(!routes) {
				this.error = true;
				this.loading = false;
				this.page -= 1;
				this.lastPage -= 1;
				return;
			}



			if(routes.length < this.pageSize) { // no more routes
				this.noMore = true;
			}
			routes = routes.filter(r => !this.ids.includes(r.id)); // filter out already loaded routes (idk why rest api returns duplicates)

			this.ids = [...this.ids, ...routes.map(r => r.id)];

			this.routes = [...this.routes, ...routes]; // append or overwrite
			this.loading = false;
			this.error = false;
		}
  }
}
</script>

<style scoped>
.filters-con{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
	margin-top: 2rem;
	padding: 1rem;
	border: 1px solid #ccc;
	border-radius: 0.5rem;
}

.filters{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	gap: 1.2rem;
	width: 100%;
}

.filters .filter {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.routes-con {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.routes-con .routes {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 1.2rem;
	margin-top: 2rem;
	padding: 1rem;

	width: 100%;
}

@media screen and (max-width: 500px){
	.filters-con{
		flex-direction: column;
		width: 100%;
	}
	.filters{
		flex-direction: column;
		width: 100%;
	}

	.filters .filter {
		width: 100%;
	}
	.filters .filter input, .filters .filter .p-calendar {
		width: 100% !important;
		flex: 1;
	}
	.routes-con .routes {
		flex-direction: column;
	}
}

.skeletons {
    width: 100%;
		display: flex;
		flex-direction: column;
		gap: 1.2rem;
		max-width: 1248px;
}



#masonry {
		width: 100%;
		height: 100px;
		background: transparent;
}

</style>

<style>
.skeletons .pu-skeleton {
    width: 100%;
    border-radius: 1rem;
}

@media screen and (max-width: 800px) {
    .skeletons {
				align-items: center;
    }
	.skeletons .pu-skeleton {
      height: 246px !important;
      max-width: 406px;
	}
}
</style>
